<template>
  <div class="brain-popover rule-edit-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body">
        <el-form
          :model="ruleObj"
          :rules="rules"
          ref="modelForm"
          label-width="140px"
        >
          <el-form-item
            :label="$t('rule.modality_type') + '：'"
            prop="modality"
          >
            <el-select v-model="ruleObj.modality" filterable placeholder>
              <el-option
                v-for="item in modalityList"
                :key="item.id"
                :label="item.modality"
                :value="item.modality"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('rule.modality_desc') + '：'"
            prop="description"
          >
            <el-input v-model="ruleObj.description" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rule.series_type') + '：'" prop="prototype">
            <el-select v-model="ruleObj['prototype']" filterable placeholder>
              <el-option
                v-for="item in seriesList"
                :key="item.id"
                :label="item.series_type"
                :value="item.series_type"
              ></el-option>
            </el-select>
            <!-- <el-input v-model="ruleObj['prototype']"></el-input> -->
          </el-form-item>
          <el-form-item :label="$t('rule.key_word') + '：'" prop="origin">
            <el-input v-model="ruleObj.origin"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitRule('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ruleRequest } from "../../api/api";

export default {
  props: {
    ruleObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
    modalityList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    popoverTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      seriesList: [],
    };
  },
  computed: {
    rules() {
      return {
        modality: [{ required: true, message: " ", trigger: "change" }],
        // description: [{ required: true, message: " ", trigger: "change" }],
        // prototype: [{ required: true, message: " ", trigger: "change" }],
        origin: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    selectedModality(val) {
      let params = {
        request: "series_list",
        userid: this.$store.state.userid,
        modality: val,
      };
      this.getSeriesList(params);
    },
    async getSeriesList(params) {
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.seriesList = res.data ? res.data : [];
        } else {
          this.seriesList = [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    submitRule(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let rule = {
            modality: this.ruleObj.modality,
            prototype: this.ruleObj["prototype"],
            origin: this.ruleObj.origin,
          };
          if (this.ruleObj.currentid) {
            rule.currentid = this.ruleObj.currentid;
          }
          this.$emit("submitRule", rule);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    },
  },
  watch: {
    "ruleObj.modality": function (val, oldVal) {
      if (val !== oldVal) {
        let params = {
          request: "series_list",
          userid: this.$store.state.userid,
          modality: val,
        };
        this.getSeriesList(params);

        function findModality(modality) {
          return modality.modality === val;
        }
        let _index = this.modalityList.findIndex(findModality);
        if (_index > -1) {
          this.ruleObj.description = this.modalityList[_index].description;
        }
      }
    },
  },
};
</script>

<style lang="less">
.rule-edit-popover {
  .popover-body {
    padding-right: 80px;
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
