<template>
  <div class="tabel-content">
    <el-table
      ref="seriesTable"
      :data="seriesList"
      :empty-text="$t('no_data')"
      style="width: 100%"
      header-row-class-name="thead-dark"
    >
      <el-table-column
        prop="modality"
        :label="$t('rule.modality_type')"
      ></el-table-column>
      <el-table-column
        prop="series_type"
        :label="$t('rule.series_type')"
      ></el-table-column>

      <el-table-column
        prop="parent_realname"
        :label="$t('rule.creator') + '(' + $t('sign.username') + ')'"
      >
        <template v-slot="scope">
          {{ scope.row.parent_realname || scope.row.realname }} ({{
            scope.row.parent_username || scope.row.username
          }})
        </template>
      </el-table-column>

      <el-table-column
        prop="operate"
        :label="$t('operate')"
        align="center"
        width="150"
      >
        <template v-slot="scope">
          <span
            class="cursor text-primary mr10"
            @click="showSeriesModel(scope.row)"
            >{{ $t("edit") }}</span
          >
          <span
            class="cursor text-danger"
            @click="deleteSeries(scope.row.id)"
            >{{ $t("delete") }}</span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    seriesList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  methods: {
    deleteSeries(id) {
      this.$emit("delete-series", id);
    },
    showSeriesModel(series) {
      this.$emit("showSeriesModel", series);
    },
  },
};
</script>
