<template>
  <div class="data-manage">
    <div class="main-content">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ pageTitle }}</span>
          <span class="total-num" v-if="tabactive === 'rule'">{{
            totalRule
          }}</span>
          <span class="total-num" v-if="tabactive === 'series'">{{
            totalSeries
          }}</span>
        </div>
        <div class="float-right tab">
          <span
            class="tab-link"
            :class="tabactive === 'rule' ? 'tab-link-active' : ''"
            @click="tabChange('rule')"
            >{{ $t("rule.rules") }}</span
          >
          <span
            class="tab-link"
            :class="tabactive === 'series' ? 'tab-link-active' : ''"
            @click="tabChange('series')"
            >{{ $t("rule.series") }}</span
          >
          <button
            type="button"
            class="btn btn-danger btn-sm ml10"
            @click="showSeriesAddModel"
          >
            {{ $t("rule.add_series") }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm ml10"
            @click="showRuleAddModel"
          >
            {{ $t("rule.add_key") }}
          </button>
        </div>
      </div>
      <keep-alive>
        <div class="rule-content" v-if="tabactive === 'rule'">
          <!-- <div class="search-wrapper">
            <form class="form-inline">
              <button type="submit" class="btn btn-dark btn-sm">
                {{ $t("search") }}
              </button>
              <div class="form-group">
                <input
                  type="text"
                  name="search"
                  class="form-control"
                  placeholder
                />
              </div>
            </form>
          </div> -->
          <div class="tabel-content">
            <el-table
              ref="multipleTable"
              :data="ruleList"
              :empty-text="$t('no_data')"
              style="width: 100%"
              header-row-class-name="thead-dark"
            >
              <el-table-column
                prop="modality"
                :label="$t('rule.modality_type')"
              ></el-table-column>
              <el-table-column
                prop="modality_description"
                :label="$t('rule.modality_desc')"
              ></el-table-column>
              <el-table-column
                prop="prototype"
                :label="$t('rule.series_type')"
              ></el-table-column>
              <el-table-column
                prop="origin"
                :label="$t('rule.key_word')"
              ></el-table-column>

              <el-table-column
                prop="parent_realname"
                :label="$t('rule.creator') + '(' + $t('sign.username') + ')'"
              >
                <template v-slot="scope">
                  {{ scope.row.parent_realname || scope.row.realname }} ({{
                    scope.row.parent_username || scope.row.username
                  }})
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('operate')"
                align="center"
                width="150"
              >
                <template v-slot="scope">
                  <span
                    class="cursor text-primary mr10"
                    @click="showRuleEditModel(scope.row)"
                    >{{ $t("edit") }}</span
                  >
                  <span
                    class="cursor text-danger"
                    @click="handleDeleteRule(scope.row.id)"
                    >{{ $t("delete") }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="clearfix mt-3 mb-3">
            <div class="float-left">
              <pagination
                :pageSize="pageSize"
                :layout="layout"
                :currentPage="ruleCurPage"
                :total="totalRule"
                @goToPage="handleRuleCurrentChange"
              />
            </div>
          </div>
        </div>
        <div class="rule-content" v-else-if="tabactive === 'series'">
          <div class="search-wrapper">
            <span class="search-btn" @click.stop="getSeriesList">{{
              $t("search")
            }}</span>
            <el-form :inline="true" class="search-form" size="small">
              <el-form-item>
                <el-input
                  v-model="searchModality"
                  clearable
                  :placeholder="$t('rule.modality_type')"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <series-table
            :seriesList="seriesList"
            @delete-series="handleDeleteSeries"
            @showSeriesModel="showSeriesEditModel"
          ></series-table>
          <div class="clearfix mt-3 mb-3">
            <div class="float-left">
              <pagination
                :pageSize="pageSize"
                :layout="layout"
                :currentPage="seriesCurPage"
                :total="totalSeries"
                @goToPage="handleSeriesCurrentChange"
              />
            </div>
          </div>
        </div>
      </keep-alive>
    </div>
    <series-edit-model
      :showPopover="showSeriesModel"
      :seriesObj="seriesObj"
      :modalityList="modalityList"
      :popoverTitle="seriesPopoverTitle"
      @closePopover="closeSeriesModel"
      @submitSeries="submitSeries"
    ></series-edit-model>
    <rule-edit-model
      :showPopover="showRuleModel"
      :ruleObj="ruleObj"
      :modalityList="modalityList"
      :popoverTitle="rulePopoverTitle"
      @closePopover="closeRuleModel"
      @submitRule="submitRule"
    ></rule-edit-model>
  </div>
</template>

<script>
import { ruleRequest } from "../../api/api";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";
import seriesEditModel from "../../components/rule/seriesEditModel";
import ruleEditModel from "../../components/rule/ruleEditModel";
import seriesTable from "./seriesTable";

export default {
  mixins: [paginationMixin],
  components: {
    pagination,
    ruleEditModel,
    seriesEditModel,
    seriesTable,
  },
  data() {
    return {
      pageTitle: this.$t("rule.rules"),
      tabactive: "rule",
      searchModality: "",
      ruleList: new Array(),
      seriesList: new Array(),
      modalityList: new Array(),
      seriesObj: {},
      showSeriesModel: false,
      seriesPopoverTitle: "",
      seriesModelType: "",
      ruleObj: {},
      showRuleModel: false,
      rulePopoverTitle: "",
      ruleModelType: "",
      pageSize: 15,
      ruleCurPage: 1,
      totalRule: 0,
      seriesCurPage: 1,
      totalSeries: 0,
      user: this.$store.state.user,
    };
  },
  created() {
    this.getRuleList();
    this.getSeriesList();
    this.getModalityList();
  },
  methods: {
    tabChange(tabname) {
      this.tabactive = tabname;
      this.pageTitle =
        tabname === "rule"
          ? this.$t("rule.rules")
          : tabname === "series"
          ? this.$t("rule.series")
          : "";
    },
    async getRuleList() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        request: "list",
        userid: this.$store.state.userid,
        limit: this.pageSize,
        offset: this.ruleCurPage - 1,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.ruleList = res.data ? res.data : [];
          this.totalRule = res.total || 0;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getSeriesList() {
      let params = {
        request: "series_list",
        modality: this.searchModality,
        userid: this.$store.state.userid,
        limit: this.pageSize,
        offset: this.seriesCurPage - 1,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.seriesList = res.data ? res.data : [];
          this.totalSeries = res.total || 0;
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    async getModalityList() {
      let params = {
        request: "modality_list",
        userid: this.$store.state.userid,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.modalityList = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    showRuleAddModel() {
      this.showRuleModel = true;
      this.rulePopoverTitle = this.$t("rule.add_key");
      this.ruleModelType = "add";
    },
    showRuleEditModel(rule) {
      this.showRuleModel = true;
      this.rulePopoverTitle = this.$t("rule.edit_key");
      this.ruleModelType = "edit";
      this.ruleObj = {
        currentid: rule.id,
        modality: rule.modality,
        prototype: rule["prototype"],
        origin: rule.origin,
        description: rule.modality_description,
      };
    },
    closeRuleModel() {
      this.showRuleModel = false;
      this.ruleObj = {};
    },
    async submitRule(rule) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let request =
        this.ruleModelType === "add"
          ? "insert"
          : this.ruleModelType === "edit"
          ? "update"
          : "";
      let params = {
        request: request,
        userid: this.$store.state.userid,
        ...rule,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getRuleList();
          this.closeRuleModel();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteRule(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteRule(id);
        })
        .catch(() => {});
    },
    async deleteRule(id) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "remove",
        userid: this.$store.state.userid,
        currentid: id,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getRuleList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    showSeriesAddModel() {
      this.showSeriesModel = true;
      this.seriesPopoverTitle = this.$t("rule.add_series");
      this.seriesModelType = "add";
    },
    showSeriesEditModel(series) {
      this.showSeriesModel = true;
      this.seriesPopoverTitle = this.$t("rule.edit_series");
      this.seriesModelType = "edit";
      this.seriesObj = {
        series_id: series.id,
        modality: series.modality,
        series_type: series.series_type,
      };
    },
    closeSeriesModel() {
      this.showSeriesModel = false;
      this.seriesObj = {};
    },
    async submitSeries(series) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let request =
        this.seriesModelType === "add"
          ? "series_insert"
          : this.seriesModelType === "edit"
          ? "series_update"
          : "";
      let params = {
        request: request,
        userid: this.$store.state.userid,
        ...series,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSeriesList();
          this.closeSeriesModel();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteSeries(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteSeries(id);
        })
        .catch(() => {});
    },
    async deleteSeries(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "series_remove",
        userid: this.$store.state.userid,
        series_id: id,
      };
      try {
        let res = await ruleRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getSeriesList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleSeriesCurrentChange(val) {
      this.seriesCurPage = val;
      this.getSeriesList();
    },
    handleRuleCurrentChange(val) {
      this.ruleCurPage = val;
      this.getRuleList();
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.tab {
  .tab-link {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border: 1px solid #ddd;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #212529;
    cursor: pointer;
    &.tab-link-active {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }
}
</style>
