<template>
  <div class="brain-popover series-edit-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body">
        <el-form
          :model="seriesObj"
          :rules="rules"
          ref="modelForm"
          label-width="140px"
        >
          <el-form-item
            :label="$t('rule.modality_type') + '：'"
            prop="modality"
          >
            <el-select v-model="seriesObj.modality" filterable placeholder>
              <el-option
                v-for="item in modalityList"
                :key="item.id"
                :label="item.modality"
                :value="item.modality"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('rule.series_type') + '：'"
            prop="series_type"
          >
            <el-input v-model="seriesObj.series_type"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitSeries('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    seriesObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
    modalityList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    popoverTitle: {
      type: String,
      default: "",
    },
  },
  // data() {
  //   return {
  //     options: ["MR", "PT"]
  //   };
  // },
  computed: {
    rules() {
      return {
        modality: [{ required: true, message: " ", trigger: "change" }],
        series_type: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  methods: {
    submitSeries(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitSeries", this.seriesObj);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    },
  },
};
</script>

<style lang="less">
.series-edit-popover {
  .popover-body {
    padding-right: 80px;
  }
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
